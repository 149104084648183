.gallery-image-web {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    .uploaded {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
    }
    .uploaded-by-me {
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
    }
    .deleted-web {
        position: absolute;
        top: 25%;
        left: 1rem;
        font-size: 3rem;
    }
    img {
        width: 100%;
    }
    .image-datetime {
        font-size: 10px;
    }
}
