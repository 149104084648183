checklist-list-view {
    width: 100%;
}

.checklist-questions-container {
    padding-top: 0 !important;

    checklist-questions {
        padding: 0 1rem;
    }

    ion-toolbar {
        position: fixed;
        top: 3.5rem;
        left: 0;
        height: 1.2rem;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
    }

    ion-tab-button {
        font-size: 1rem;
    }

    .swiper-pagination {
        position: fixed !important;
        bottom: 3.5rem;
    }

    .checklist-section-header {
        background-color: #3e4a59;
        color: white;
        margin-top: 0.3rem;
        margin-bottom: 0;
        padding: 0.3rem 1.5rem;
        text-align: center;

        .header {
            font-size-adjust: 1.1rem;
        }

        &.header-hidden {
            visibility: hidden;
            height: 0;
        }
    }

    .photo-only {
        border-bottom: 1px solid #dedede;
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: -0.7rem;

        .photo-only-text {
            padding: 0.5rem 0;
            width: 100%;
        }
    }

    // .checklist-questions-landscape {
    //     height: calc(100vh - 7rem) !important;
    //     width: 100%;
    // }

    checklist-questions {
        display: block;
        margin-bottom: 0;
        display: block;
        //height: calc(100vh - 11rem);
        height: calc(100vh - 10rem);
        width: 100%;
        top: 1rem;
        z-index: 5000;
        overflow-y: auto;

        &.landscape-with-keyboard {
            height: calc(100vh - 5rem);
        }
    }

    ion-datetime {
        ion-picker-column:nth(2) {
            display: none;
        }
    }

    .section-highlight {
        background-color: #fff7e9 !important;
        --ion-item-background: #fff7e9 !important;

        ion-item {
            --ion-item-background: #fff7e9 !important;
        }
    }
}

.checklist-questions-tabs {
    ion-tab-button {
        font-size: 0.9rem;
        font-weight: bold;
    }
}

.checklist-image-skeleton {
    position: relative;

    &:before {
        padding-top: 120%;
        content: "";
        display: block;
    }

    .img {
        position: absolute;
        display: flex;
        justify-content: center;
        background-color: #fafafa;
        align-items: center;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
}

.checklist-image-caption {
    width: 100%;
    overflow: hidden;
    height: 1.2rem;
    line-height: 1.2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.7rem;
}

.question-item-correction-needed {
    border: 1px solid red;
    display: block;
    margin-bottom: 1px;
    padding: 0 0.5rem;
    // div {
    //     background-color: orange;
    // }
    // ion-item {
    //     --ion-background-color: orange;
    // }
}

.question-item-correction-completed {
    border: 1px solid #79e879;
    display: block;
    margin-bottom: 1px;
    padding: 0 0.5rem;
}

.asset-replaced-summary-item {
    background-color: lightgray;

    ion-item {
        --ion-background-color: lightgray !important;
    }

    ion-list {
        --ion-background-color: lightgray !important;
    }
}


.question-web {
    border: 1px solid lightgray;
    padding: 0 5px;
    margin: 5px 0;

    .label-question {
        border-bottom: none;
        font-size: 18px;
        text-align: center;
    }

    ion-icon {
        pointer-events: none;
    }
}

.questions-web-images {
    overflow-y: auto;

    .question-history {
        float: right;
        cursor: pointer;
        color: blue;
        text-decoration: underline;
    }

    .questions-web-images-list {
        display: inline-flex;
        overflow-y: auto;

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            display: block;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .questions-web-images--item {
            border: 1px solid lightgray;
            margin: 2px;
            padding: 2px;
            width: 5rem;
            height: 5rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;

            ion-badge {
                position: absolute;
                left: 1px;
                bottom: 1px;
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}