.battery-test-form {
  h1 {
    background-color: lightgray;
    padding: 0.5rem;
    text-align: center;
  }

  input,
  select {
    padding: 0.25rem;
  }

  .battery-test-form--configuration {
    width: 100%;
    td {
      text-align: left;
      &.header {
      }
    }
    input,
    select {
      width: 90%;
    }

    .divider-row {
      td {
        height: 1rem;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: calc(100% - 0.5rem);
          border-bottom: 1px solid gray;
          left: 0rem;
          top: 50%;
        }
      }
    }
  }

  .header-container {
  }

  .data-container {
    table {
      border-spacing: 5px !important;
      border-collapse: inherit;
    }
    .data-container-header {
      position: sticky;
      top: -1.3rem;
      z-index: 5;
      margin-bottom: 0.5rem;
      table {
        height: 4.3rem;
        overflow-y: hidden;
        thead {
          th {
            background-color: lightgray;
            padding: 0.5rem;
            text-align: left;
          }
        }
        tbody {
          visibility: hidden;
          td {
            font-size: 0;
            line-height: 0;
            height: 0;
            input {
              height: 0px;
              padding: 0;
            }
          }
        }
      }
    }

    .data-container-data {
      overflow-x: auto;
      margin-top: -1rem;
      table {
        thead {
          visibility: hidden;
          th {
            padding: 0 0.5rem;
            font-size: 0;
            line-height: 0;
            height: 0;
          }
        }
      }
    }
  }
}
