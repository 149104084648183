.my-work-action-inactive {
  opacity: 0.5;
}

.my-work-item {
  .site-name {
    font-size: 1.2rem;
    color: var(--ion-color-dark);
    padding-left: 1.2rem;
  }

  .project-name {
    font-size: 0.9rem;
    color: var(--ion-color-dark-tint);
    padding-left: 1.2rem;
  }

  .job-name {
    font-size: 0.9rem;
    color: var(--ion-color-dark-tint);
    padding-left: 1.2rem;
  }

  .job-status {
    position: absolute;
    top: 0.85rem;
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .job-checkedin {
    position: absolute;
    right: 0.5rem;
    top: 0.8rem;
    color: #fbcb09;

    .icon {
      animation: spin 4s linear infinite;
    }
  }

  .job-lead,
  .job-additional-technician {
    position: absolute;
    top: 2.05rem;
    width: 15px;
    height: 15px;
    background-color: var(--ion-color-light-shade);

    &:before {
      content: "L";
      font-size: 0.7rem;
      color: white;
      position: absolute;
      top: 0.1rem;
      left: 0.2rem;
    }
  }

  .job-additional-technician {
    background-color: var(--header-background-color) !important;

    &:before {
      content: "T";
    }
  }
}

.job-data-loading {
  background-color: #f04242;
  color: white;
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
}

.my-jobs-progress-bar {
  position: fixed;
  left: 0;
  top: 3.5rem;
  z-index: 1;
}

.animate-rotate {
  animation: spin 4s linear infinite;
}

.my-work-item-job-date {
  position: sticky;
  top: -1rem;
  z-index: 5;
  padding: 0.2rem 1rem;
  color: var(--header-background-color);
  font-size: 0.9rem;
  font-weight: bold;
  background-color: var(--ion-color-light-tint);
}

.time-tracking-sticky-date {
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 0.2rem 1rem;
  color: var(--header-background-color);
  font-size: 0.9rem;
  font-weight: bold;
  background-color: var(--ion-color-light-tint);

  .time-tracking-sticky-date-details {
    display: flex;
    justify-content: start;
    align-items: center;
    border-top: 1px solid lightgray;
    color: gray;
  }
}

.time-tracking-input-wrapper {
  display: inline-block;
  position: relative;
  margin-right: 10px;

  &.date {
    input {
      max-width: 140px;
    }
    label {
      top: 2px;
    }
  }

  input {
    max-width: 70px;
    padding: 0.7em 0.2em;
    text-align: center;
    font-size: 20px;
  }

  label {
    position: absolute;
    top: 0;
    right: 5px;
    color: gray;
  }
}

.time-tracking-unit {
  font-size: 14px;
  color: gray;
}

.time-tracking-total-summary {
  position: sticky;
  bottom: 0;
  background-color: lightgray;
  padding: 10px;
  font-size: 1.2rem;

  .time-tracking-total-summary-total {
    border-bottom: 1px solid gray;
  }
}

.my-work-details-modal {
  checklist-button {
    ion-card {
      margin: 0.2rem 0.2rem 1rem 0;

      ion-card-header {
        padding-top: 0.5rem;
      }

      ion-card-content {
        padding: 0.2rem;
      }
    }
  }
}

.my-work-details-container {
  .facility-icon {
    position: absolute;
    font-size: 1.5rem;
    margin: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    left: 0;
    color: var(--ion-color-dark-tint) !important;
    width: 5rem;
  }

  ion-card-header {
    padding-bottom: 0;
    display: flex;
    justify-content: center;

    ion-card-title {
      display: flex;
      flex-basis: 100%;
      justify-content: space-between;
      padding-left: 3rem;
    }
  }
}

#my-work-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
}

.work-details-status-buttons-container {
  padding: 0.5rem;
}

.my-work-search-button {
  position: fixed;
  top: 0;
  z-index: 500;
  right: 0.8rem;
  top: 0.8rem;
  padding: 0;
  font-size: 1.7rem;
  color: white;
}

.my-work-search {
  position: fixed;
  top: 0;
  z-index: 500;
  width: 50%;
  right: 1rem;
  top: 0.5rem;
  padding: 0;

  .searchbar-search-icon {
    display: none !important;
  }

  input {
    padding-left: 1rem !important;
  }
}

.work-item-highlight {
  background-color: #ffe6b8 !important;
  --ion-item-background: #ffe6b8 !important;

  ion-item {
    --ion-item-background: #ffe6b8 !important;
  }
}

.job-details-item {
  border-bottom: 1px solid lightgray;
  padding: 0.2rem;
  margin: 0.2rem;

  label {
    font-weight: bold;
    margin-right: 0.2rem;

    &.block {
      display: block;
    }
  }

  .sms {
    float: right;
  }

  .phone {
    float: right;
    margin-right: 1rem;
  }
}

.job-status-notes {
  font-size: 12px;
  color: #3e4a59;
}

.job-status-name {
  text-decoration: underline;
}

.more-jobs-action-sheet {
  z-index: 50001 !important;
}

.newer-data-available {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
  background-color: orange;
  color: white;
}

.new-job-hand-held-scanner-toast {
  margin-top: -4rem !important;
}

.time-off {
  ion-datetime {
    margin: 0 auto;
  }
}