.scheduling-container {
    padding: 0.5rem;
    $quote-filter-bg-color: #eaeaea;
    background-color: $quote-filter-bg-color;
    ion-item {
        --ion-background-color: $quote-filter-bg-color;
    }
    ion-list {
        --ion-background-color: $quote-filter-bg-color;
    }
}

.scheduling-additional-assets-container {
    padding: 0.5rem;
    $quote-filter-bg-color: #e7f3da;
    background-color: $quote-filter-bg-color;
    ion-item {
        --ion-background-color: $quote-filter-bg-color;
    }
    ion-list {
        --ion-background-color: $quote-filter-bg-color;
    }
}