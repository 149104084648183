.menu-indicator {
    border-radius: 50%;
    background-color: orange;
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    width: 0.5rem;
    height: 0.5rem;
}

.main-menu-icon {
    font-size: 1.5rem;
    margin-right: 1.5rem;
    width: 2rem;
    text-align: center;
}
