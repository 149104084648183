// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "assets/assets.scss";
@import "batteries/batteries.scss";
@import "shopping-cart/shopping-cart.scss";
@import "checklist/questions.scss";
@import "gallery/gallery.scss";
@import "./utils";
@import "./menu";
@import "./facility";
@import "scheduling/scheduling";
@import "my-work/my-work";
@import "my-locations/my-locations";
@import "map/map";
@import "battery-tests/battery-tests.scss";

// input,
// select,
// textarea {
//     border-bottom: 1px solid lightgray !important;
// }

.syncscroll {
    overflow-x: scroll;
}

@media (pointer: fine) {
    .syncscroll {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: block;
            height: 10px;
            background-color: #fafafa;
        }

        &::-webkit-scrollbar-thumb {
            display: block;
            background: #aaa;
        }
    }
}

.fab {
    contain: layout;
}

ion-fab-button {
    .button-native {
        border-radius: unset !important;
        width: 100% !important;
        padding: 0 50px !important;
        right: 50px !important;
    }
}

ion-datetime {
    padding-left: 0 !important;
}

ion-note {
    font-size: 11px !important;
}

.loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    background-color: #fafafa;
    z-index: 1;
    opacity: 0.5;
    display: none;
}

ion-toolbar {
    z-index: 101 !important;
    --ion-background-color: var(--header-background-color);

    ion-title,
    ion-icon,
    ion-label,
    ion-button {
        color: white;
    }

    ion-note {
        color: lightgray;
    }
}

.color-gray {
    color: #3e4a59;
}

asset-data-review {
    .card-content {
        padding: 0 !important;
    }
}

ion-label {
    white-space: normal !important;
}

asset-data-overview {
    ion-badge {
        margin-left: 10px !important;
    }
}

oms-header {
    ion-title {
        padding-left: 0 !important;
    }
}

asset-help {
    .help-fab-button {
        display: inline-block !important;
    }
}

.help-assets-button {
    display: inline-block !important;
    width: 25px;
    height: 25px;
    margin: 0 0.2rem -0.3rem 0.2rem;

    ion-icon {
        font-size: 1rem;
    }
}

.asset-review-small-button {
    padding-left: 15px !important;
    padding-right: 5px !important;
}

.asset-review-count-badge {
    padding-left: 15px;
    padding-right: 5px;
}

ion-select {
    max-width: 100% !important;
    padding-left: 0 !important;
}

ion-reorder {
    font-size: 25px;
    color: gray;
}

ion-textarea {
    &.autogrow {
        textarea {
            min-height: 57px !important;
        }
    }
}

.text-truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.list--padding-with-fab-btn {
    padding-bottom: 5rem !important;
}

oms-group-select {
    width: 100%;

    .picker-element {
        display: flex;
        align-items: center;
        width: 100%;

        .icon {
            color: var(--ion-color-medium-tint);
        }

        &.disabled {
            color: #a2a2a2;
        }
    }
}

ion-back-button {
    color: white;
}

.clear-date {
    z-index: 5;
    margin: 0;
    position: absolute;
    right: 3rem;
}

.alert-tappable {
    contain: unset !important;
    height: auto !important;
}

.oms-environment {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.7rem;
    color: gray;
}

.oms-bottom-trademark {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.alert-radio-label {
    white-space: normal !important;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.stamp {
    transform: rotate(12deg);
    color: white;
    font-weight: 700;
    border: 0.1rem solid white;
    display: inline-block;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    border-radius: 0.5rem;
    font-family: "Courier";
    mix-blend-mode: multiply;
}

.preview-stamp {
    height: 2rem;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 0.5rem;
    right: -1rem;
    width: 9rem;
    z-index: 50000;
    text-align: center;

    .stamp {
        position: absolute;
        z-index: 5;
        font-size: 1rem;
        font-weight: normal;
        background-color: var(--header-background-color);
        opacity: 0.7;
        color: yellow;

        .subtitle {
            font-size: 0.7rem;
        }
    }
}

.login-logo {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    ion-img {
        width: 75%;
    }
}

.action-sheet-button-inner {
    word-break: break-word;
}

.alert-wrapper {
    .alert-button-group {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        border-top: 1px solid #ececec !important;

        :last-child {
            border-bottom: 1px solid transparent !important;
        }

        .alert-button {
            flex-basis: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #e7e7e7;

            .alert-button-inner {
                justify-content: center !important;
                text-align: center !important;
            }
        }
    }
}

.input-with-borders {
    border: solid 1px gray;
    border-radius: 0.5rem;
}

.date-picker-modal {
    --background: rgba(44, 39, 45, 0.2);

    &::part(content) {
        backdrop-filter: blur(6px);
    }

    ion-content {
        --background: transparent;
        --padding-top: 25vh;
        --padding-start: 20px;
        --padding-end: 20px;

        ion-datetime {
            border-radius: 8px;

            @media screen and (min-width: 500px) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.date-picker-add-job {
    --background: rgba(44, 39, 45, 0.2);

    &::part(content) {
        backdrop-filter: blur(6px);
    }

    ion-content {
        --background: transparent;
        --padding-top: 25vh;
        --padding-start: 20px;
        --padding-end: 20px;

        ion-datetime {
            border-radius: 8px;
            margin: 0 auto;
        }
    }
}