.facility-site-walk-wrapper {
}

.facility-icon {
    font-size: 64px;

    &.site-walk-icon {
        color: #2f3292;
        margin-top: 1rem;
    }

    &.facility-assets-icon {
        color: #f14725;
        margin-top: 1rem;
    }

    &.facility-quote-icon {
        margin-top: 1rem;
    }

    &.facility-scan-lookup-icon {
        margin-top: 1rem;
    }

    &.facility-scan-icon {
    }
}

.facility-site-walk-last-updated {
    text-align: center;
    font-size: 10px;
    color: gray;
}

.facility-site-walk-info {
    display: flex;
    justify-content: space-around;
}

.facility-subtititle {
    padding-top: 0.5rem;
}
