.cart-item-title {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    .cart-item-options {
        display: flex;
        align-self: flex-start;
        width: 8rem;
        justify-content: space-between;

        .fa-minus {
            margin-right: 1rem;
        }
        .fa-plus {
            margin-left: 1rem;
            margin-right: 1rem;
        }
        .fa-trash {
            margin-left: 1rem;
        }
    }
}

.cart-item-battery-model {
    font-size: 14px;
}

.shopping-cart-divider {
    border-bottom: 1px solid lightgray;
    margin: 1rem 0;
    &.ion-no-margin {
        margin: 0;
    }
}

.quote-filter-container {
    $quote-filter-bg-color: #eaeaea;
    background-color: $quote-filter-bg-color;
    ion-item {
        --ion-background-color: $quote-filter-bg-color;
    }
    ion-list {
        --ion-background-color: $quote-filter-bg-color;
    }
}

.cart-email-container {
    padding-top: 0.5rem;
    $email-container-bg-color: #eaeaea;
    background-color: $email-container-bg-color;
    ion-item {
        --ion-background-color: $email-container-bg-color;
    }
    ion-list {
        --ion-background-color: $email-container-bg-color;
    }
}

.cart-site-walk-summary-container {
    $site-walk-summary-bg-color: #fff7e9;
    padding: 0.5rem 0;
    font-size: 12px;
    margin-bottom: 1rem;
    background-color: $site-walk-summary-bg-color;
    position: relative;
    .cart-site-walk-summary-header {
        margin-bottom: 0.1rem;
    }
    .cart-site-walk-summary {
        display: flex;
        justify-content: space-around;
    }
    ion-item {
        --ion-background-color: $site-walk-summary-bg-color;
    }

    .toggle-container {
        position: absolute;
        top: 25%;
        right: 0.5rem;
        width: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
            color: var(--ion-color-medium);
        }
        .icon {
            color: #70ad48;
            font-size: 2rem;
        }
    }
}

.cart-site-items {
    ion-item {
        --border-width: 0;
        --inner-border-width: 0;
    }
}

.cart-site-walk-last-updated {
    margin-top: 0.2rem;
    font-size: 10px;
}

.image-selector {
    position: relative;
    margin: 0.7rem 0.5rem;
    .count {
        position: absolute;
        color: var(--ion-color-medium);
        top: -7px;
        font-size: 0.9rem;
    }
}

.quote-heatmap {
    margin: 0.8rem 0;
    font-size: 12px;
}

.quote-quantity-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
    .quote-quantity {
        width: 2.4rem;
        height: 1.4rem;
        margin-left: 0.5rem;
        margin-bottom: 0.2rem;
        text-align: center;
        font-size: 14px;
    }
}
