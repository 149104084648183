.battery-item--additional-info {
    text-align: left;
    margin-left: 1.3rem;
    min-height: 25px;

    .battery-item--additional-info-data {
        font-size: 10px;
        color: gray;
        font-style: italic;
    }
}

.battery-item--container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.battery-item--first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
    ion-button {
        --padding-start: 0.5rem;
        --padding-end: 0.5rem;
    }
}

.battery-item--battery-type-label {
    padding-top: 1rem;

    .battery-item--battery-type-label-text {
        position: absolute;
        top: 5px;
    }
}

.battery-scan-item--header {
    position: absolute;
    top: 0px;
    left: 0;
    font-style: italic;
    color: gray;
}

.battery-item-photo {
    position: relative;
    z-index: 5;
    padding: 0.5rem;
    outline: none;
    cursor: pointer;

    .count {
        position: absolute;
        top: 0.25rem;
        font-size: 0.8rem;
        color: darkgray;
    }
    ion-icon {
        font-size: 1.5rem !important;
        color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54) !important;
    }
}
