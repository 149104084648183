.asset-item--type-description {
  color: darkgray;
  font-size: 0.7rem;
}

.custom-input-ion-item {
  ion-note {
    width: 7rem !important;
    margin-right: 0 !important;
  }
}

.asset-breadcrumb {
  text-align: center;
  padding: 0.3rem;
  .asset-breadcrumb--item {
    border: 1px solid lightgray;
    padding: 0.2rem 0.5rem;
    margin: 0 0.2rem;
    display: inline-block;
    margin-top: 5px;
    &.selected {
      background-color: #eaeaea;
    }
  }
}

.asset-item-details-wrapper {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid gray;
  padding: 5px 0;
  z-index: 2;
  display: flex;
  .left {
    flex-basis: 50%;
  }
  .right {
    flex-basis: 50%;
  }
  .asset-item-details {
    margin-left: 1rem;
    font-size: 12px;
    &:not(:last-child) {
      border-bottom: 1px dotted lightgray;
    }
    .label {
      color: gray;
    }
    .text {
    }
  }
}

.asset-highlight {
  background-color: #ffe6b8 !important;
  --ion-item-background: #ffe6b8 !important;
  ion-item {
    --ion-item-background: #ffe6b8 !important;
  }
}

.sub-asset-options-btn {
  font-size: 10px;
  margin-top: -2px;
  margin-left: 10px;
  float: right;
}

.battery-actions-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 7rem;
  height: 1rem;
  align-items: center;
}

.quote-in-process {
  position:relative;
  .quote-in-process-dot {
    position: absolute;
    background-color: orange;
    border-radius: 100%;
    width: 0.4rem;
    bottom: 0;
    right: 0.1rem;
    height: 0.4rem;
  }
}