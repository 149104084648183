.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.color-primary {
    color: var(--ion-color-primary-tint);
}

.color-secondary {
    color: var(--ion-color-secondary-tint);
}

.color-success {
    color: var(--ion-color-success-tint);
}

.color-warning {
    color: var(--ion-color-warning-tint);
}

.color-danger {
    color: var(--ion-color-danger-tint);
}

.color-dark {
    color: var(--ion-color-dark-tint);
}

.color-medium {
    color: var(--ion-color-medium);
}

.color-light {
    color: var(--ion-color-light-shade);
}

.color-white {
    color: white;
}

.text-italic {
    font-style: italic;
}

.text-linethrough {
    text-decoration: line-through;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

ion-icon {
    &.color-white {
        --ion-color: white;
    }
}
